@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.ant-dropdown-menu {
  padding: 0 !important;
  width: 10em;
}

.custom-dropdown {
  width: 240px;
  margin-top: -0.8rem !important;

  svg .a {
    fill: $iconDefault;
  }

  .ant-dropdown-menu-item {
    font-size: 16px;
    font-weight: bold;
    padding: 15px 10px;
    color: $ceruleanBlue;
  }

  .ant-dropdown-menu-item:hover {
    background: $solitude;
  }

  .ant-dropdown-menu-item > .anticon:first-child {
    font-size: 16px;
  }
}

.show-avatar {
  display: none !important;
}

.bold.hidden-name {
  font-size: 16px !important;
}

@media screen and (max-width: 1178px) {
  .hidden-name {
    display: none;
  }

  .show-avatar {
    display: inline-block !important;
  }

}