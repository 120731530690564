@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.header-logo {
  width: 230px;
}

.header-right {
  position: absolute !important;
  right: 1.5rem;
}

.header-translator {
  display: inline-block;
}

.header-logo-fixed,
.simple-logo {
  width:230px;
}

@media (max-width: 535px) {
  .header-logo,
  .header-logo-fixed {
    display: none;
  }
}

.avatar-position {
  padding-left: 30px;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  align-self: flex-end;
  display: inline-block;
}

.dropdown-icon {
  padding-bottom: 2px;
}

.calaris-header {
  font-size: 24px;
  font-weight: 500;
}

.username-header {
  color: $chambray;
  font-weight: 500;
  cursor: pointer;
}

.sider-button-content {
  width: auto !important;
}

.margin-user-menu {
  display: inline-block;
}

@media (max-width: 1178px) {
  .avatar-hidden {
    display: none;
  }
}

.avatar-siderbutton {
  background: $ceruleanBlue !important;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.ant-avatar.ant-avatar-icon {
  background: $ceruleanBlue;
}

.to-home {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  margin-right: auto;

  svg .a{
    fill: $ceruleanBlue;
  }

  span {
    margin-left: 10px;
    margin-bottom: -0.3em;
  }
}

@media (max-width: 590px) {
  .to-home {
    display: none;
  }
}

.product-title {
  margin-left: 3.5em;
  font-size: 20px;
  color: $trout;
  align-self: flex-end;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  text-align: initial;
  margin-bottom: 0.3em;

  .category-name,
  .product-name{
    line-height: 24px;
    margin-left: 5px;
  }

  .product-name {
    font-size: 18px;
  }

  a {
    line-height: 0;
    font-size: 10px;
    margin-top: 8px;

    img {
      margin-right: 5px;
    }
  }
}

@media (max-width: 768px) {
  .product-title {
    width: 53%;
  }
}

.support {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  font-size: 16px;
  color: $chambray;
  line-height: 20px;
  margin-right: 15px;
}

.ant-avatar .avatar-siderbutton .ant-avatar-circle .ant-avatar-icon {
  padding-left: 30px;
}

.ant-btn-icon-only {
  padding: 0 15px !important;
}

.margin-user-menu {
  .ant-dropdown.ant-dropdown-placement-bottomCenter{
    top: 85px !important;
  }

  ul {
    width: 18.9em;
  }
}

.sider-button,
.form-drawer-button {
  display: none;
}

@media (max-width: 1024px) {
  .sider-button,
  .form-drawer-button {
    display: block;
  }

  .support {
    display: none;
  }

  .product-title {
    margin-left: 0 !important;
  }
}

@media (max-width: 820px) {
  .product-title {
    display: none;
  }
}

@media (max-width:500px) {
  .logo-responsive {
    display: none;
  }
}
