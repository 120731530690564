@import 'colors';
@import 'variables';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

html,
body {
  min-height: 100%;
}

body {
  font-family: 'Roboto';
  color: $trout;
  font-weight: normal;
}

a, a:active {
  color: $chambray;
}

a:hover {
  color: $chambray;
  text-decoration: underline;
}

.pre-login-container {
  height: 100%;
  background-color: $whiteBackground !important;
}

.submenu-title {
  margin-left: 0.7rem;
}

.label-light {
  font-size: 16px;
  font-weight: bold;
  color: $santasGray;
}

.pie-chart-dot {
  margin-right: 1rem;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  display: inline-block;
}

.title-chart-dot {
  margin-right: 0.3rem;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  display: inline-block;
}

.square-chart {
  display: inline-block;
  height: 8px;
  width: 8px;
  transform: rotate(45deg);
}

.icon-button-margin {
  margin-right: 0.3rem;
}

.register-card-margin-external {
  margin-left: 1rem;
}

@media screen and (max-width: $md) {
  .register-card-margin-external {
    margin-left: 1em;
  }
}

.files-uploaded-list {
  z-index: 1;

  ol {
    padding-left: 1em;
    padding-right: 2em;

    li {
      word-break: break-word;
    }
  }
}