@import '../../styles/colors.scss';

.ant-form-item-label {
  display: flex;
  width: 100%;
  line-height: 12px !important;
}

.ant-form-item-control-input-content {
  width: 100%;
}

.row-with-form-item {
  display: flex;
  flex: 0 0 100%;
  align-items: baseline;
  justify-content: space-around;
  margin-bottom: 10px;

  .ant-form-item-no-colon {
    width: 100%;
  }

  .clear-button {
    position: absolute;
    right: 0;
    top: 0.65rem;
    cursor: pointer;
    color: $ceruleanBlue;
    z-index: 2;
  }

  .ant-radio-group {
    display: flex;
  }

  .ant-radio-wrapper {
    display: flex;
    margin-bottom: 10px;

    .ant-radio {
      margin-top: 0.4em;
      height: 16px;
    }
  }

  .contact-label {
    font-weight: bold;
  }

  .radio-option {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width:720px) {
  .row-with-form-item {
    flex-wrap: wrap;
  }
}

.ant-form-item-label-disabled {
  font-size: 14px;
  color: $iron;
}

.mt-5.full {
  width: 100%;
}
