.n-login {
  margin: 0 !important;
}

.initial-layout {
  justify-content: center;
  padding: 9em 0;

  .add-user-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}

.no-login-translator {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
}