.responsive-logo {
  width: 80%;
  min-width: 280px;
  max-width: 450px;
}

.responsive-header-logo {
  width: 50%;
  max-width: 450px;
  min-width: 230px;
}

.hrp-log {
  height: 4.3rem;
  width: auto;
  align-self: flex-end;
  margin-left: 20px;
}