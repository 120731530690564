$gunmetal: #505655;
$chambray: #2254B9;
$secondChambray: #334D80;
$alabaster: #F9F9F9;
$santasGray: #9EA0A5;
$trout: #4D4F5C;
$valencia: #D63E43;
$chateauGreen: #3BAD4B;
$alto: #d9d9d9;
$fireBush: #E3AA30;
$pigeonPost: #B1B7D8;
$zircon: #F7F8FF;
$seaGreen: #2D9C3C;
$mystic: #EAEDF3;
$astronaut: #303C7E;
$sanMarino: #505DA8;
$nobel: #B5B5B5;
$tuna: #3E3F42;
$white: white;
$blueBell: #8A93C5;
$ronchi: #E9BB59;
$athensGray: #E1E3E9;
$silverChalice: #B1B1B1;
$blueZodiac: #0D2440;
$anzac:#E7B64D;
$mariner: #1F65C0;
$whiteBackground: white;
$grayBackground: #D8DCE6;
$headerBackground: #F7F8FA;
$label: #9EA0A5;
$ceruleanBlue: #2254B9;
$border: #D8DCE6;
$text: #4D4F5C;
$footerLink: #3C4B9E;
$geyser: #D8DCE6;
$iron: #CED0D4;
$midNightBlue: #1A3670;
$solitude: #E8EAF0;
$mischka: #ADB0B8;
$shipCove: #8090B0;
$prussianBlue: #002060;
$border-default: #707070;
$iconDefault: #B3BCCF;
$mercury: #E8E8E8;
$linkWater: #CCD2DF;
$lighBlack: rgba(0, 0, 0, 0.33);

:export {
  nobel: $nobel;
  tuna: $tuna;
  white: $white;
  trout: $trout;
  astronaut: $astronaut;
  chambray: $chambray;
  blueBell: $blueBell;
  fireBush: $fireBush;
  ronchi: $ronchi;
  ceruleanBlue: $ceruleanBlue;
  blueZodiac: $blueZodiac;
  anzac: $anzac;
  geyser: $geyser;
  mariner: $mariner;
  whiteBackground: $whiteBackground;
  grayBackground: $grayBackground;
  headerBackground: $headerBackground;
  label: $label;
  border: $border;
  text: $text;
  footerLink: $footerLink;
  iron: $iron;
  midNightBlue: $midNightBlue;
  mischka: $mischka;
  shipCove: $shipCove;
  prussianBlue: $prussianBlue;
  linkWater: $linkWater;
  iconDefault: $iconDefault
}
