@import "colors.scss";

$sizes: 20;

@mixin margin-classes {
  @for $i from 0 through $sizes {
     $margin: $i * 0.5rem;
    /* margin #{$margin} */
    .m-#{$i}  {margin: $margin;}
    .ml-#{$i} {margin-left: $margin;}
    .mr-#{$i} {margin-right: $margin;}
    .mt-#{$i} {margin-top: $margin;}
    .mb-#{$i} {margin-bottom: $margin;}
    .mx-#{$i} {margin-left: $margin; margin-right: $margin;}
    .my-#{$i} {margin-top: $margin; margin-bottom: $margin;}
  }
}
@include margin-classes;


@mixin padding-classes {
  @for $i from 0 through $sizes {
    $padding: $i * 0.5rem;
    /* padding #{$padding} */
    .p-#{$i} {padding: $padding;}
    .pl-#{$i} {padding-left: $padding;}
    .pr-#{$i} {padding-right: $padding;}
    .pt-#{$i} {padding-top: $padding;}
    .pb-#{$i} {padding-bottom: $padding;}
    .px-#{$i} {padding-left: $padding; padding-right: $padding;}
    .py-#{$i} {padding-top: $padding; padding-bottom: $padding;}
  }
}
@include padding-classes;

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
  width: 100%;
  justify-content: center;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.vh-100 {
  min-height: 100vh;
}

.bt-trout {
  border-top: 1px solid $trout;
}

.bb-trout {
  border-bottom: 1px solid $trout;
}

.bl-trout {
  border-left: 1px solid $trout;
}

.br-trout {
  border-right: 1px solid $trout;
}

.bt-mystic {
  border-top: 1px solid $mystic;
}

.bold {
  font-weight: bold;
}

.font-regular {
  font-size: 14px;
}

.font-small {
  font-size: 12px;
}

.font-medium {
  font-size: 16px;
}

.font-large {
  font-size: 20px;
}

.font-xlarge {
  font-size: 24px;
}

.background-color-primary {
  background-color: $chambray;
}

.color-primary {
  color: $chambray;
}

.color-santas-grey {
  color: $santasGray !important;
}

.color-trout {
  color: $trout;
}

.display-inline-block {
  display: inline-block;
}

.float-right {
  float: right;
}

.display-inline {
  display: inline;
}

.table-title {
  text-align: right !important;
  padding-right: 1rem !important;
  padding-bottom: 0 !important;
}

.table-title-left {
  text-align: left !important;
  padding-right: 1rem !important;
  padding-bottom: 0 !important;
}

.ant-table-thead .table-title-children {
  text-align: right !important;
  padding-top: 0 !important;
  padding-right: 1rem !important;
}

.ant-table-thead .table-title-children-left {
  text-align: left !important;
  padding-top: 0 !important;
  padding-right: 1rem !important;
}

.table-title-children-left {
  text-align: left !important;
  padding-right: 1rem !important;
}

.table-title-children {
  text-align: right !important;
  padding-right: 1rem !important;
}

.column-right {
  text-align: right !important;
  padding: 10px 1rem !important;
}

.bb-solitude {
  border-bottom: 1px solid $solitude;
}

.link-water-circle {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  display: inline-block;
  background-color: $linkWater;
}

.download-btn,
.download-btn:hover,
.download-btn:focus
 {
  border: 2px solid $ceruleanBlue;
  color: $ceruleanBlue;
  width: 100%;
}

.mh-100 {
  min-height: 100%;
}

.p-relative {
  position: relative;
}

.prussian-dot {
  height: 5px;
  width: 5px;
  border-radius: 5px;
  display: inline-block;
  background-color: $prussianBlue;
}

.font-mischka {
  color: $mischka;
}
