@import './variables.scss';

@media screen and (max-width: $lg) {
  .ant-alert-banner {
    height: auto;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    padding-left: 4.5rem;
    padding-right: 5rem !important;
  }

  .ant-alert-icon {
    left: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .ant-layout-sider {
    display: none;
  }

  .ant-layout-content {
    left: 0;
  }
}