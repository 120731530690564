@import '../../styles/colors.scss';

.translation-selector {
  width: 6rem;

  .language {
    text-align: center;
    height: 1rem;
    font-size: 14px;
    line-height: 1rem;
    cursor: pointer;
    font-weight: bold;
    color: $mischka;

    &.selected {
      color: $text;
    }
  }

  .first-border {
    border-right: 1px solid $iron;
  }
}