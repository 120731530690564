@import "./src/styles/colors.scss";

.contact-phone {
  position: absolute;
  bottom: 6.5em;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);

  img {
    margin-top: -12px;
    margin-right: 10px;
  }
}

.phone-number {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text-phone1{
    height: 12px;
    font-size: 10px;
    text-align: center;
    margin-bottom: 5px;
  }
  .text-phone2{
    height: 14px;
    font-size: 14px;
    font-weight: bold;
  }
}

.logo-sider {
  margin-bottom: 15px;
  margin-top: 15px;
}

@media (min-width: 536px) {
  .logo-sider {
    display: none;
  }
}

.alert-team-member {
  width: 25px;
  height: 25px;
  background-color: $fireBush;
  border-radius: 25px;
  margin-left: 10px;
  color: white !important;
  line-height: 25px;
  font-weight: 500;
  text-align: center;
  position: absolute;
  right: 15px;
}

.menu-title {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
