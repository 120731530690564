@import '../../styles/colors.scss';

.pre-login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    font-size: 16px;
    font-weight: bold;
    color: $trout;
    margin-bottom: 5px;
  }

  .ant-input,
  .ant-btn {
    max-width: 20%;
  }

  .ant-input {
    margin-bottom: 10px;
  }

  .ant-btn-primary {
    color: white;
    background: $ceruleanBlue;
    border-radius: 4px;

    span {
      color: white;
    }
  }
}