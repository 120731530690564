@import '../../styles/colors.scss';

.footer {
  font-size: 12px !important;
  color: $footerLink !important;
  padding: 2.5em 3em !important;
  font-weight: 300;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 427px) {
  .footer {
    justify-content: center;
    padding-bottom: 1.5em !important;
    padding-top: 1.5em !important;
  }
}

.page-options {
  display: flex;
  align-items: center;
  justify-content: space-around;
  a {
    margin-left: 1.5em;
  }
}

