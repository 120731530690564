@import "colors.scss";

#root {
  height: 100%;
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus {
  background-color: $chambray;
  border-color: $chambray;
  width: 100%;
  height: 38px;
  font-size: 16px;
  color: $white !important;
}

.ant-card-head {
  background-color: $alabaster;
  text-align: center;
  font-size: 20px;
  padding: 0;
  color: $trout;
  font-weight: normal;
}

.ant-card-head-title {
  padding: 20px 0px;
  overflow: initial;
  white-space: initial;
}

.ant-form-item-label > label {
  color: $santasGray;
  font-weight: 500;
}

.ant-layout, .ant-layout-footer {
  background-color: $whiteBackground;
}

.ant-alert-banner {
  height: 50px;
  padding-top: 0.9rem;
  padding-left: 11rem;
  padding-right: 11rem !important;
  position: absolute;
  top: 0px;
  z-index: 100;
}

.ant-alert-message {
  color: $white;
  font-weight: bold;
}

.ant-alert-error {
  background-color: $valencia;
}

.ant-alert-icon {
  left: 140px;
  top: 15px;
}

.ant-alert-close-icon {
  line-height: 29px;
}

.ant-alert-banner {
  width: 100%;
  z-index: 100;
}

.ant-form-item-control {
  width: 100%;
}

.ant-form-item-label {
  line-height: 30px;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $seaGreen;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $seaGreen;
  border-color: $seaGreen;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: $white;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.ant-checkbox-wrapper {
  font-size: 16px !important;
}

.ant-checkbox-inner {
  width: 17px;
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.ant-card-body {
  padding: 35px;
  font-size: 16px;
}

.ant-alert-success {
  background-color: $chateauGreen;
}

.ant-alert-warning {
  background-color: #E3AA30;
}

.ant-btn:hover, .ant-btn:focus {
  color: inherit;
  border-color: $alto;
}

.ant-badge-count {
  background-color: $fireBush;
  border-color: $fireBush;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;

  svg .a {
    fill: $chambray;
  }
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  content: none;
}

.ant-menu-item-selected {
  color: $chambray !important;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  font-weight: bold;
  font-size: 16px;
  border-top: 1px solid rgba(1, 1, 1, 0.1);

  svg .a {
    fill: $pigeonPost;
  }
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 5px;
}

.ant-menu-item:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $chambray;
  font-weight: bold !important;

  svg .a {
    fill: $chambray;
  }
}

.ant-menu-item-active {
  color: inherit;
}


.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: transparent;
}

.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, $trout, $trout);
}

.ant-menu-submenu-selected {
  color: $chambray;

  span svg .a {
    fill: $chambray !important;
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: $zircon;
  font-weight: bold;

  svg .a {
    fill: $chambray;
  }
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: $santasGray;
  background-color: $whiteBackground;
}

.ant-form-item-control {
  line-height: normal;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $seaGreen;
  background-color: $seaGreen;
}

.ant-radio-inner::after {
  background-color: $whiteBackground;
  width: 6px;
  height: 6px;
  top: 4px;
  left: 4px;
}

.ant-radio-wrapper {
  font-size: 16px !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $seaGreen;
}

.ant-menu-submenu, .ant-menu-submenu-inline {
  border-bottom: 1px solid $mystic;
  border-top: 1px solid $mystic;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  min-height: 48px;
  line-height: 48px;
  height: fit-content;
}

.ant-menu-submenu-open .ant-menu-item-selected {
  font-weight: bold !important;
}

.ant-menu-submenu-open .ant-menu-item-selected::before {
  content: '';
  position: relative;
  border-right: 3px solid $chambray;
}

.ant-tooltip-arrow::before {
  background-color: white;
}

.ant-tooltip-inner {
  padding: 10px 20px;
  font-size: 16px;
  background-color: white;
  color: $text;
}

.ant-form-explain {
  margin-top: 0.2rem;
}

.ant-form-item-explain {
  min-height: 0;
  height: auto;
}

.ant-input::placeholder {
  color: $athensGray;
}

.ant-input:hover,
.ant-input:focus,
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: $chambray;
}

.ant-form label {
  font-size: 12px;
}

.ant-form {
  margin-bottom: 5px;
  width: 100%;
}

.ant-menu-inline .ant-menu-item {
  margin-top: 0px !important;
}

.ant-menu-sub.ant-menu-inline {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.ant-message-error .anticon {
  color: $valencia;
}

.ant-message-success .anticon {
  color: $chateauGreen;
}

.ant-switch {
  height: 35px;
  min-width: 83px;
}

.ant-switch-loading-icon,
.ant-switch::after {
  width: 29px;
  height: 29px;
  top: 2px;
  left: 2px;
}

.ant-switch-inner {
  font-size: 13px;
  font-weight: bold;
}

.ant-switch-checked::after {
  left: 100%;
}

.ant-switch-checked .ant-switch-inner {
  margin-right: 2.5rem;
}

.ant-switch-checked {
  background-color: $chambray;
}

.ant-pagination-item:focus,
.ant-pagination-item:hover,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover,
.ant-pagination-item-active {
  border-color: $chambray;
  background-color: $chambray;
  color: $white;

  a, a:active {
    color: white;
  }
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $chambray;
  border-color: $chambray;
}

.ant-dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
}

.ant-table-body {
  overflow-x: auto;
}

.ant-tooltip-inner {
  font-size: 14px;
}

.ant-card {
  border-radius: 4px;
}

.ant-modal-body {
  padding: 24px 19px;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: $midNightBlue;
}

.ant-select-selection:hover,
.ant-select-selection:active,
.ant-select-open .ant-select-selection
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-open .ant-select-selection {
  border-color: $ceruleanBlue;
}

.ant-select-dropdown-menu-item-selected {
  font-weight: normal !important;
}

.ant-layout-header {
  background: $white;
  height: 70px;
  box-shadow: 0 2px 0 $mystic;
  position: fixed;
  z-index: 95;
  width: 100%;
  padding: 0 30px !important;

  .ant-row-flex.ant-row-flex-middle {
    margin-top: 0.3em;
  }

}

.ant-layout-sider {
  border-right: 1px solid $solitude;
  margin-top: 70px;
  overflow: auto;
  height: 100%;
  position: fixed;
  left: 0;
  background: white;
}

.ant-layout-content,
.left-margin.ant-layout-content {
  width: auto;
  background: $headerBackground;
  padding: 25px;
  margin-top: 70px;
  height: auto;
}

.left-margin.ant-layout-content {
  margin-left: 280px !important;
}

.ant-progress-bg {
  background-color: $shipCove;
}

.anticon.anticon-close.ant-modal-close-icon {
  color: inherit;
  font-style: normal;
  font-size: 11px;
  text-align: center;
  vertical-align: 1em;
  margin-left: 1.5rem;
}

.ant-row-center,
.ant-form-item {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 10px;
}

.ant-modal-content {
  border-radius: 4px;
}

.ant-modal-header {
  border-bottom: 1px solid $border;
}

.ant-modal-footer {
  border-top: 1px solid $border;
}

.ant-select-dropdown {
  //z-index: 80;
}

.ant-table tbody > tr.ant-table-row-selected > td {
  background: unset;
}

.ant-table-column-sorters {
  color: $mischka;
  align-items: flex-start;

  .column-title {
    align-items: flex-start;

    img {
      margin-top: -5px;
    }
  }
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: $ceruleanBlue;
}

.ant-table thead > tr > th,
.ant-table thead th.ant-table-column-sort {
  background: $headerBackground;
  vertical-align: baseline;
}

.ant-table tbody > tr > td {
  vertical-align: top;
}

.ant-btn-primary[disabled] {
  border-color: $iron;
  background-color: $iron;
  border-radius: 4px;
}

.ant-spin {
  color: $midNightBlue;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

.ant-select-show-search.ant-select-multiple .ant-select-selector input {
  min-width: 5px;
}

.ant-select-selection-search {
  min-width: 5px;
}

.ant-tabs-tab {
  color: #363636 !important;
}

.ant-tabs-bar {
  border-bottom: none;
}

.ant-tabs-tab {
  font-size: 18px;
}

.ant-tabs-tab-active {
  font-weight: bold;
  font-size: 18px;
  border-bottom-color: $chambray !important;
  color: $chambray !important;
}

.ant-tabs-ink-bar {
  background-color: $chambray !important;
}
