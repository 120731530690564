.loading-page {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 2002;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  .anticon.anticon-loading.ant-spin-dot {
    font-size: 5em;
  }
}